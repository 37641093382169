import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

//action types
export const UPDATE_TOTE_DATA = "updateWMSPACKINGToteData";
export const UPDATE_SKU_TASKS = "updateWMSPACKINGSkuTasks";
export const CONFIRM_SINGLE_PACKING = "confirmWMSPACKINGSinglePacking";
export const CONFIRM_BATCH_PACKING = "confirmWMSPACKINGBatchPacking";
export const CREATE_NEW_BOX = "updateWMSPACKINGCreateNewBox";
export const UPDATE_PACKING_STORE = "updateWMSPACKINGStore";
export const UPDATE_BOX_DETAILS = "updateWMSPACKINGBoxDimension";

//mutation types
export const SET_ORDERS_TABLE_DATA = "setWMSPACKINGOrdersTableData";
export const SET_PICK_TYPE = "setWMSPACKINGPickType";
export const SET_TASKS_DATA = "setWMSPACKINGTasks";
export const SET_SKU_TASKS = "setWMSPACKINGSkuTasks";
export const SET_VIRTUAL_SKU_TAKS = "setWMSPACKINGVirtualSkuTasks";
export const SET_SELECTED_TOTE = "setWMSPACKINGSelectedTote";
export const SET_SEARCHED_SKU = "setWMNSPACKINGSearchedSku";
export const SET_BATCH_PACKING_BOX = "setWMSPACKINGBatchPackingBox";
export const SET_PACKING_RESPONSE = "setWMSPACKINGResponse";
// export const UPDATE_SKU_TASK_QTY = "updateWMSPACKINGSkuTaskQty";
// export const UPDATE_VIRTUAL_SKU_TASK_QTY = "updateWMSPACKINGVirtualSkuTaskQty";
export const SET_ORDER_DETAILS = "setWMSPACKINGOrderDetails";
export const SET_BOX_INFO = "setWMSPACKINGBoxInfo";
export const SET_ENTERED_TOTE = "setWMSPACKINGEnteredTote";
export const SET_IS_FULLY_PACKED = "setWMSPACKINGIsFullyPacked";
export const SET_ALLOW_AUTO_SAVE = "setWMSPACKINGIsAutoSaveAllowed";

const state = {
  orders_table_data: null,
  pick_type: null,
  sku_tasks: null,
  virtual_sku_tasks: null,
  selected_tote: null,
  searched_sku: null,
  batch_packing_box_items: [],
  packing_response: {
    message: "",
    status: false,
    success: false,
  },
  order_details: null,
  box_info: null,
  entered_tote: null,
  is_fully_packed: null,
  is_autosave_allowed: null,
};

//getters
const getters = {
  getWMSPACKINGOrdersTableData(state) {
    return state.orders_table_data;
  },
  getWMSPACKINGPickType(state) {
    return state.pick_type;
  },
  getWMSPACKINGSKUTasks(state) {
    return state.sku_tasks;
  },
  getWMSPACKINGVirtualSKUTasks(state) {
    return state.virtual_sku_tasks;
  },
  getWMSPACKINGSelectedTote(state) {
    return state.selected_tote;
  },
  getWMSPACKINGSearchedSku(state) {
    return state.searched_sku;
  },
  getWMSPACKINGBatchPackingBox(state) {
    return state.batch_packing_box_items;
  },
  getWMSPACKINGRespose(state) {
    return state.packing_response;
  },
  getWMSPACKINGOrderDetails(state) {
    return state.order_details;
  },
  getWMSPACKINGBoxInfo(state) {
    return state.box_info;
  },
  getWMSPACKINGEnteredTote(state) {
    return state.entered_tote;
  },
  getWMSPACKINGIsFullyPacked(state) {
    return state.is_fully_packed;
  },
  getWMSPACKINGIsAutoSaveAllowed(state) {
    return state.is_autosave_allowed;
  },
};

// actions
const actions = {
  [UPDATE_TOTE_DATA](context, payload) {
    context.commit(SET_ORDERS_TABLE_DATA, null);
    context.commit(SET_SKU_TASKS, null);
    context.commit(SET_PICK_TYPE, null);
    context.commit(SET_SELECTED_TOTE, null);
    // context.commit(SET_BATCH_PACKING_BOX, null);
    context.commit(SET_PACKING_RESPONSE, {
      message: "",
      status: false,
      success: false,
    });
    context.commit(SET_PAGE_LOADING, true);

    return ApiService.post(`/warehouse_management/packing/show`, payload)
      .then(({ data }) => {
        context.commit(SET_ORDERS_TABLE_DATA, data.orders);
        context.commit(SET_PICK_TYPE, data.pick_type);
        if (data.pick_type === 1) {
          context.commit(SET_SKU_TASKS, null);
        } else if (data.pick_type === 2) {
          context.commit(SET_ORDER_DETAILS, data.order_details);
          context.commit(SET_SKU_TASKS, data.tasks);
          context.commit(SET_BATCH_PACKING_BOX, data.boxes);
          context.commit(SET_ALLOW_AUTO_SAVE, data.is_allow_auto_save);
        }
        context.commit(SET_SELECTED_TOTE, payload.tote_id);
      })
      .finally(() => {
        context.commit(SET_PAGE_LOADING, false);
      });
  },
  [UPDATE_SKU_TASKS](context, payload) {
    context.commit(SET_PAGE_LOADING, true);

    return new Promise((resolve) => {
      ApiService.post(`/warehouse_management/packing/search_sku`, payload)
        .then(({ data }) => {
          context.commit(SET_SKU_TASKS, data.tasks);
          context.commit(SET_SEARCHED_SKU, payload.sku);
          context.commit(SET_ORDER_DETAILS, data.order_details);
          resolve();
        })
        .finally(() => {
          context.commit(SET_PAGE_LOADING, false);
        });
    });
  },
  async [CONFIRM_SINGLE_PACKING](context, payload) {
    await ApiService.post(`/warehouse_management/packing/update`, payload)
      .then((res) => {
        context.commit(SET_PACKING_RESPONSE, res.data);
        Swal.fire({
          title: `Updated`,
          text: `Packing updated successfully`,
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
      })
      .finally(() => {
        // context.commit(SET_SKU_TASKS, null);
        context.commit(SET_PAGE_LOADING, false);
      });
  },
  async [CONFIRM_BATCH_PACKING](context, payload) {
    await ApiService.post(`/warehouse_management/packing/update`, payload)
      .then((res) => {
        context.commit(SET_PACKING_RESPONSE, res.data);
        Swal.fire({
          title: `Updated`,
          text: `Packing updated successfully`,
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
      })
      .finally(() => {
        // context.commit(SET_SKU_TASKS, null);
        // context.commit(SET_BATCH_PACKING_BOX, []);
        context.commit(SET_PAGE_LOADING, false);
      });
  },
  [CREATE_NEW_BOX](context, payload) {
    context.commit(SET_PAGE_LOADING, true);
    return ApiService.post(`/warehouse_management/packing/create`, payload)
      .then(({ data }) => {
        context.commit(SET_BOX_INFO, data.box);
      })
      .finally(() => {
        context.commit(SET_PAGE_LOADING, false);
      });
  },
  async [UPDATE_PACKING_STORE](context, payload) {
    await ApiService.post(
      `/warehouse_management/packing/store_b2c_packing`,
      payload
    ).then(({ data }) => {
      context.commit(SET_SKU_TASKS, data.tasks);
      context.commit(SET_BATCH_PACKING_BOX, data.boxes);
    });
  },
  async [UPDATE_BOX_DETAILS](context, payload) {
    await ApiService.post(`/warehouse_management/packing/update`, payload).then(
      ({ data }) => {
        if (context.state.pick_type === 1) {
          const isFullyPacked =
            data.orders.length === 0 && data.is_fully_packed;
          context.commit(SET_IS_FULLY_PACKED, isFullyPacked);
        }
      }
    );
  },
};

// mutations
const mutations = {
  [SET_ORDERS_TABLE_DATA](state, payload) {
    state.orders_table_data = payload;
  },
  [SET_PICK_TYPE](state, pickingType) {
    state.pick_type = pickingType;
  },
  [SET_SKU_TASKS](state, skus) {
    state.sku_tasks = skus;
  },
  [SET_VIRTUAL_SKU_TAKS](state, skus) {
    state.virtual_sku_tasks = skus;
  },
  [SET_SELECTED_TOTE](state, tote) {
    state.selected_tote = tote;
  },
  [SET_SEARCHED_SKU](state, sku) {
    state.searched_sku = sku;
  },
  [SET_BATCH_PACKING_BOX](state, items) {
    if (items.length) {
      state.batch_packing_box_items = items;
    } else state.batch_packing_box_items = [];
  },
  // [UPDATE_SKU_TASK_QTY](state, item) {
  //   const index = state.sku_tasks.findIndex(
  //     (element) =>
  //       element.sku.text1 === item.sku && element.tote_id === item.tote_id
  //   );

  //   state.sku_tasks[index].qty -= Number(item.packed_qty);
  //   if (state.sku_tasks[index].qty > 0) {
  //     state.sku_tasks[index].wms_status_label = "Partially packed";
  //   } else state.sku_tasks[index].wms_status_label = "Packed";
  // },
  // [UPDATE_VIRTUAL_SKU_TASK_QTY](state, item) {
  //   const index = state.virtual_sku_tasks.findIndex(
  //     (element) =>
  //       element.sku.text1 === item.sku.text1 && element.tote_id === item.tote_id
  //   );
  //   state.virtual_sku_tasks[index].qty -= Number(item.qty);
  // },
  [SET_PACKING_RESPONSE](state, res) {
    state.packing_response = res;
  },
  [SET_ORDER_DETAILS](state, details) {
    state.order_details = details;
  },
  [SET_BOX_INFO](state, info) {
    state.box_info = info;
  },
  [SET_ENTERED_TOTE](state, tote) {
    state.entered_tote = tote;
  },
  [SET_IS_FULLY_PACKED](state, value) {
    state.is_fully_packed = value;
  },
  [SET_ALLOW_AUTO_SAVE](state, value) {
    state.is_autosave_allowed = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
